import { z } from "zod";
import { AbortOption, request, RequestError } from "@/lib/api/request";
import DefaultAvatar from "@public/assets/icons/default-avatar.svg";
import { isAxiosError } from "axios";

export type User = z.infer<typeof userSchema>;
export const userSchema = z
    .object({
        _id: z.string(),
        name: z.string(),
        avatar: z.string(),
        username: z.string(),
    })
    .transform(({ _id, avatar, ...rest }) => ({
        id: _id,
        avatarUrl: avatar.length ? avatar : DefaultAvatar,
        ...rest,
    }));

export type Me = Awaited<ReturnType<typeof getMe>>;

export class TokenExpiredError extends Error {
    constructor() {
        super('Token expired');
    }
}

export class ForbiddenError extends Error {
    constructor() {
        super('Access forbidden');
    }
}

export async function getMe({ signal }: AbortOption = {}) {
    const responseSchema = z
        .object({
            data: z
                .object({
                    _id: z.string(),
                    name: z.string(),
                    avatar: z.string(),
                    username: z.string(),
                    email: z.string(),
                    isPerformer: z.boolean(),
                })
                .transform(({ _id, avatar, isPerformer, ...rest }) => ({
                    id: _id,
                    avatarUrl: avatar.length ? avatar : DefaultAvatar,
                    artist: isPerformer,
                    ...rest,
                })),
        })
        .transform((res) => res.data);

    try {
        const res = await request("GET", "/users/me", {
            signal,
        });
        const me = responseSchema.parse(res);
        return me;
    } catch (error) {
        if (isAxiosError(error)) {
            if (error.response?.status === 401) {
                throw new TokenExpiredError();
            }
            if (error.response?.status === 403) {
                throw new ForbiddenError();
            }
        }
        throw error;
    }
}

export type UpdateMeOptions = Partial<RegisterUserOptions>;
export type UpdateMeErrorCode = "emailTaken" | "usernameTaken";
export class UpdateMeError extends RequestError<UpdateMeErrorCode> {}

export async function updateMe({ username, email, password }: UpdateMeOptions) {
    const res = await request("PUT", "/users", {
        data: {
            username,
            email,
            password,
        },
        signal: null,

        // We handle errors programatically below
        validateStatus: (status) => [200, 400, 422].includes(status),
    });

    const successResponseSchema = z
        .object({
            status: z.literal(0),
        })
        .transform(() => ({
            statusCode: 200 as const,
        }));

    const errorResponseSchema = z.object({
        statusCode: z.union([z.literal(400), z.literal(422)]),
    });

    const responseSchema = z.union([
        successResponseSchema,
        errorResponseSchema,
    ]);

    const data = responseSchema.parse(res);

    switch (data.statusCode) {
        case 200:
            return data;
        case 400:
            throw new UpdateMeError("usernameTaken");
        case 422:
            throw new UpdateMeError("emailTaken");
    }
}

export type RegisterUserOptions = {
    username: string;
    email: string;
    password: string;
};
export type RegisterUserErrorCode = "emailTaken" | "usernameTaken";
export class RegisterUserError extends RequestError<RegisterUserErrorCode> {}

export async function registerUser({
    username,
    email,
    password,
}: RegisterUserOptions) {
    const res = await request("POST", "/auth/users/register", {
        data: {
            username,
            email,
            password,
        },
        signal: null,

        // We handle errors programatically below
        validateStatus: (status) => [200, 400, 422].includes(status),
    });

    const successResponseSchema = z
        .object({
            status: z.literal(0),
            data: z.object({
                token: z.string(),
            }),
        })
        .transform(({ data }) => ({
            statusCode: 200 as const,
            token: data.token,
        }));

    const errorResponseSchema = z.object({
        statusCode: z.union([z.literal(400), z.literal(422)]),
    });

    const responseSchema = z.union([
        successResponseSchema,
        errorResponseSchema,
    ]);

    const data = responseSchema.parse(res);

    switch (data.statusCode) {
        case 200:
            return data;
        case 400:
            throw new RegisterUserError("usernameTaken");
        case 422:
            throw new RegisterUserError("emailTaken");
    }
}
