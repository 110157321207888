import { z } from "zod";
import { AbortOption, PaginationOptions, request } from "@/lib/api/request";
import { Prettify } from "@/lib/utils";
import DefaultAvatar from "@public/assets/icons/default-avatar.svg";
import GenericHeadlineImage from "@public/assets/subscribe/generic-headline-image.jpg";
import GenericHeroImage from "@public/assets/subscribe/generic-hero-image.jpeg";
export type Artist = z.infer<typeof artistSchema>;

export type SubscribeOptions =
    | {
        type: "free";
    }
    | {
        type: "yearly";
        stripeSourceId: string;
        promo?: string;
    };

export function subscribe(artistId: string, options: SubscribeOptions) {
    const data = {
        type: options.type,
        performerId: artistId,
        paymentGateway: "stripe",
        ...(options.type === "yearly" && { cardId: options.stripeSourceId, promo: options.promo }),
    };

    return request("POST", "/payment/subscribe/performers", {
        data: data,
        signal: null,
    });
}

export const artistSchema = z
    .object({
        _id: z.string(),
        name: z.string(),
        bio: z.string().default(""),
        avatar: z.string(),
        cover: z.string().optional(),
        username: z.string(),
        stats: z.object({
            subscribers: z.number(),
        }),
        welcomeVideoPath: z.string().optional(),
        activateWelcomeVideo: z.boolean().optional(),
        yearlyPrice: z.number(),
        isSubscribed: z.boolean().optional(),
        headlineImage: z.string().optional(),
        heroImage: z.string().optional(),
        heroImageAltText: z.string().optional(),
        headlineImageAltText: z.string().optional(),
        vaultPageIntroduction: z.string().optional(),
        postsPageIntroduction: z.string().optional(),
        privacyPolicy: z.string().optional(),
        headlineOfferDescription: z.string().optional(),
        subscriptionPerks: z.array(z.string()).optional(),
        successPageTitle: z.string().optional(),
        secondarySuccessMessage: z.string().optional(),
        additionalSuccessMessage: z.string().optional(),
        successImages: z.array(z.object({
            imagePath: z.string().nullable(),
            altText: z.string().nullable(),
        })).optional(),
    })
    .transform(
        ({
            _id,
            avatar,
            cover,
            stats,
            activateWelcomeVideo,
            welcomeVideoPath,
            isSubscribed,
            headlineImage,
            heroImage,
            ...rest
        }) => ({
            id: _id,
            avatarUrl: avatar.length ? avatar : DefaultAvatar,
            headlineImage: headlineImage && headlineImage.length ? headlineImage : GenericHeadlineImage,
            heroImage: heroImage && heroImage.length ? heroImage : GenericHeroImage,
            coverUrl: cover,
            subscribers: stats.subscribers,
            welcomeVideoUrl:
                activateWelcomeVideo && welcomeVideoPath?.length
                    ? welcomeVideoPath
                    : null,
            subscribed: isSubscribed,
            ...rest,
        }),
    );

export async function getArtist(username: string, options: AbortOption = {}) {
    const responseSchema = z
        .object({
            data: artistSchema,
        })
        .transform((res) => res.data);

    const res = await request("GET", `/performers/${username}`, {
        signal: options.signal,
    });
    const artist = responseSchema.parse(res);

    return artist;
}

export type GetArtistsOptions = Prettify<PaginationOptions & AbortOption>;

export async function getArtists(options: GetArtistsOptions) {
    const responseSchema = z
        .object({
            data: z.object({
                data: z.array(artistSchema),
                total: z.number(),
            }),
        })
        .transform((res) => res.data);

    const res = await request("GET", "/performers/user/search", {
        signal: options.signal,
    });
    const artists = responseSchema.parse(res);

    return artists;
}
